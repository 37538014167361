import React from "react";
import styled from "styled-components";

import sicon from "../../assets/sicon.svg";
import { ButtonAnchor } from "../Button";
import Title from "../Title";

const staticData = {
  en: {
    ourTeamAwaits: "Our team awaits you!",
    text: "To request a service, ask a question or get an estimation, just leave us a message.",
    getInTouch: "Get in touch",
  },
  pt: {
    ourTeamAwaits: "Nossa equipe o aguarda!",
    text: "Para solicitar um serviço, tirar dúvidas ou realizar orçamentos, é só nos enviar uma mensagem.",
    getInTouch: "Get in touch",
  },
};

const Servicos = ({ data, language }) => {
  const staticInfo = staticData[language];
  const infoData = [
    {
      title: data.serviosTtulo1 || "",
      description: data.serviosDescrio1 || "",
      icon: data.serviosIcone1 ? data.serviosIcone1.fields.file.url : "",
    },
    {
      title: data.serviosTtulo2 || "",
      description: data.serviosDescrio2 || "",
      icon: data.serviosIcone2 ? data.serviosIcone2.fields.file.url : "",
    },
    {
      title: data.serviosTtulo3 || "",
      description: data.serviosDescrio3 || "",
      icon: data.serviosIcone3 ? data.serviosIcone3.fields.file.url : "",
    },
    {
      title: data.serviosTtulo4 || "",
      description: data.serviosDescrio4 || "",
      icon: data.serviosIcone4 ? data.serviosIcone4.fields.file.url : "",
    },
    {
      title: data.serviosTtulo5 || "",
      description: data.serviosDescrio5 || "",
      icon: data.serviosIcone5 ? data.serviosIcone5.fields.file.url : "",
    },
  ];

  return (
    <div id="servicos">
      <Title style={{ marginTop: 0 }}>SERVIÇOS</Title>
      <CardsWrapper>
        {infoData.map((el, i) => (
          <Card key={i}>
            <Icon src={el.icon} alt="" />
            <CardTitle>{el.title}</CardTitle>
            <StyleText>{el.description}</StyleText>
          </Card>
        ))}
        <LastCard>
          <CardTitle>{staticInfo.ourTeamAwaits}</CardTitle>
          <StyleText>{staticInfo.text}</StyleText>
          <ButtonAnchor href="#contato">{staticInfo.getInTouch}</ButtonAnchor>
        </LastCard>
      </CardsWrapper>
    </div>
  );
};

export default Servicos;

// const ServicosWrapper = styled.div``;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  width: 55px;
  height: 55px;
  margin: 20px auto;
`;

const Card = styled.div`
  background: #fdfdfd;
  box-shadow: 0px 0px 12px 4px rgba(9, 43, 63, 0.15);
  border-radius: 8px;
  padding: 15px 30px;
  margin: 5px;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  flex-shrink: 0;
  flex-grow: 0;
  @media (max-width:900px) {
    flex-basis: calc(100% - 10px);
  }
`;

const LastCard = styled.div`
  padding: 15px 30px;
  margin: 5px;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  @media (max-width:900px) {
    flex-basis: calc(100% - 10px);
  }
`;

const CardTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`;

const StyleText = styled.p`
  font-size: 14px;
  color: #484b4d;
`;
