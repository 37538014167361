import { createClient } from "contentful";

const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "5w8a5by4mfdt",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "w0MWtX8RufwSIq5XotzfX71RSEiQUTw74J6Z-ikz1pI",
});
// This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.
export default client;
