import React, { useState } from "react";
import styled, { css } from "styled-components";
import Swal from "sweetalert2";

import { Button } from "../Button";
import Title from "../Title";
import local from "../../assets/local.png";
import mail from "../../assets/mail.png";
import phoneIcon from "../../assets/phone.png";
import urlEncodeObject from "../../utils/urlEncodeObject";

const staticData = {
  en: {
    contact: 'Contact',
    send: 'Send',
    address: 'Address',
    phone: 'Phone',
    nameField: 'Name/Company',
    subjectField: 'Subject',
    messageField: 'Message',
  },
  pt: {
    contact: 'Contato',
    send: 'Enviar',
    address: 'Endereço',
    phone: 'Telefone',
    nameField: 'Nome/Empresa',
    subjectField: 'Assunto',
    messageField: 'Mensagem',
  }
}

const Contato = ({ data, language }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const staticInfo = staticData[language]

  const resetInput = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: urlEncodeObject({
        "form-name": "formulario-de-contato",
        name: name,
        email: email,
        subject: subject,
        message: message,
      }),
    })
      .then(() => {
        setLoading(false);
        Swal.fire({
          type: "success",
        });
        resetInput();
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "",
          text: "",
          type: "error",
        });
      });
  };

  return (
    <div id="contato">
      <Title>{staticInfo.contact}</Title>
      <ElWrapper>
        <FormWrapper>
          <BiggerText>
            {data.contatoDescrio ||
              "Preencha os campos e nos envie suas duvidas, pedidos e orçamentos. Nossa equipe o aguarda!"}
          </BiggerText>
          <form
            onSubmit={handleSubmit}
            action="/"
            name="formulario-de-contato"
            data-netlify="true"
          >
            <input
              type="hidden"
              name="form-name"
              value="formulario-de-contato"
            />
            <Card>
              <Input
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder={staticInfo.nameField}
                required
              />
              <Input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                required
              />
              <Input
                onChange={(e) => setSubject(e.target.value)}
                type="text"
                placeholder={staticInfo.subjectField}
                required
              />
              <TextArea
                onChange={(e) => setMessage(e.target.value)}
                placeholder={staticInfo.messageField}
                required
              />
            </Card>
            <Button type="submit">{staticInfo.send}</Button>
          </form>
        </FormWrapper>
        <InfoWrapper>
          <LittleWrapper>
            <Icon src={phoneIcon} alt="" />
            <InfoTitle>{staticInfo.phone}</InfoTitle>
          </LittleWrapper>
          <div>
            <StyleText>
              {data.contatoTelefone ||
                "55 41 3189-0069 // 55 41 99199-3754 (Alexandre Gouvêa)"}
            </StyleText>
          </div>
          <LittleWrapper>
            <Icon src={local} alt="" />
            <InfoTitle>{staticInfo.address}</InfoTitle>
          </LittleWrapper>
          <div>
            <StyleText>
              {data.contatoEndereo ||
                "Rua dos Expedicionários, 1180 Block 06 - Estradinha 83.206-450 - Paranaguá/Brasil"}
            </StyleText>
          </div>
          <LittleWrapper>
            <Icon src={mail} alt="" />
            <InfoTitle>Email</InfoTitle>
          </LittleWrapper>
          <div>
            <StyleText>
              {data.contatoEmail ||
                "argos@argosdiving.com.br argosdiving@yahoo.com"}
            </StyleText>
          </div>
        </InfoWrapper>
      </ElWrapper>
    </div>
  );
};

export default Contato;

const ElWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 200px;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    gap: 0;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`;
const LittleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FormWrapper = styled.div`
  flex: 3;
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`;

const InfoTitle = styled.h3`
  font-size: 14px;
`;

const Card = styled.div`
  background: #fdfdfd;
  box-shadow: 0px 0px 12px 4px rgba(9, 43, 63, 0.15);
  border-radius: 8px;
  padding: 15px;
  margin: 30px 0;
  flex: 1;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const inputStyle = css`
  background-color: #f9f9f9;
  color: #484b4d;
  border-radius: 5px;
  border: none;
  padding: 15px 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  margin: 5px 0;
  ::placeholder {
    color: #969c9f;
  }
  &:focus {
    outline: none;
  }
`;

const Input = styled.input`
  ${inputStyle};
`;

const TextArea = styled.textarea`
  ${inputStyle};
  resize: vertical;
`;

const StyleText = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #484b4d;
`;

const BiggerText = styled(StyleText)`
  font-size: 15px;
`;
