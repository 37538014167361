import React, { useState } from "react";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import logo from "../images/logo.png";
import menu from "../assets/menu.svg";

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

const Topbar = ({language}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      <Menu
        styles={styles}
        onClose={() => setIsSidebarOpen(false)}
        isOpen={isSidebarOpen}
        customBurgerIcon={false}
      >
        <BurgerNavList>
          <li>
            <a onClick={() => setIsSidebarOpen(false)} href="#home">
              {language === "en" ? 'Home' : 'Início'}
            </a>
          </li>
          <li>
            <a onClick={() => setIsSidebarOpen(false)} href="#sobrenos">
              {language === "en" ? 'About us' : 'Sobre Nós'}
            </a>
          </li>
          <li>
            <a onClick={() => setIsSidebarOpen(false)} href="#servicos">
              {language === "en" ? 'Services' : 'Serviços'}
            </a>
          </li>
          <li>
            <a onClick={() => setIsSidebarOpen(false)} href="#estrutura">
              {language === "en" ? 'Structure' : 'Estrutura'}
            </a>
          </li>
          <li>
            <a onClick={() => setIsSidebarOpen(false)} href="#galeria">
              {language === "en" ? 'Gallery' : 'Galeria'}
            </a>
          </li>
          <li>
            <a onClick={() => setIsSidebarOpen(false)} href="#contato">
              {language === "en" ? 'Contact' : 'Contato'}
            </a>
          </li>
        </BurgerNavList>
      </Menu>
      <GhostNavbar />
      <Navbar>
        <Container>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <Navlist>
            <li>
              <a href="#home">{language === "en" ? 'Home' : 'Início'}</a>
            </li>
            <li>
              <a href="#sobrenos">{language === "en" ? 'About us' : 'Sobre Nós'}</a>
            </li>
            <li>
              <a href="#servicos">{language === "en" ? 'Services' : 'Serviços'}</a>
            </li>
            <li>
              <a href="#estrutura">{language === "en" ? 'Structure' : 'Estrutura'}</a>
            </li>
            <li>
              <a href="#galeria">{language === "en" ? 'Gallery' : 'Galeria'}</a>
            </li>
            <li>
              <a href="#contato">{language === "en" ? 'Contact' : 'Contato'}</a>
            </li>
          </Navlist>
          <BurgerWrapper onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <img src={menu} alt="" />
          </BurgerWrapper>
        </Container>
      </Navbar>
    </>
  );
};

const Container = styled.div`
  max-width: 976px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const BurgerWrapper = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;

const GhostNavbar = styled.div`
  height: 80px;
  width: 100%;
  margin-bottom: 64px;
`;

const Navbar = styled.nav`
  position: fixed;
  background-color: rgba(248, 246, 250, 0.9);
  top: 0;
  height: 80px;
  width: 100%;
  z-index: 500;
`;

const Logo = styled.img``;

const Navlist = styled.ul`
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  display: flex;
  li {
    list-style-type: none;
  }
  a {
    text-decoration: none;
    padding: 0 16px;
    line-height: 80px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const BurgerNavList = styled.ul`
  li {
    list-style-type: none;
    &:focus {
      outline: none;
    }
    a {
      padding: 10px 0;
      display: inline-block;
    }
  }
  &:focus {
    outline: none;
  }
`;

export default Topbar;
