import React from "react";
import styled from "styled-components";

import Container from "./Container";

// const data = {
//   en: {
//     contact: 'Contact',
//     address: 'Address',
//     usefulLinks: 'Useful Links',
//     siteMap: 'Site Map',
//   },
//   en: {
//     contact: 'Contact',
//     address: 'Address',
//     usefulLinks: 'Useful Links',
//     siteMap: 'Site Map',
//   }
// }

const Footer = ({language}) => (
  <FooterWrapper>
    <ElWrapper>
      <FooterTitle>Contato</FooterTitle>
      <FooterText>
        55 41 3189-0069
        <br />
        <br />
        55 41 99199-3754 <br />
        (Alexandre Gouvêa) <br />
        <br /> argos@argosdiving.com.br <br />
        <br />
        argosdiving@yahoo.com
      </FooterText>
    </ElWrapper>
    <ElWrapper>
      <FooterTitle>Endereço</FooterTitle>
      <FooterText>
        Rua dos Expedicionários, 1180
        <br /> Block 06 - Estradinha
        <br /> 83.206-450 -<br />
        Paranaguá/Brasil
      </FooterText>
    </ElWrapper>
    <ElWrapper>
      <FooterTitle>Links Úteis</FooterTitle>
      <FooterText>
        Marine Traffic
        <br />
        Marinha do Brasil
        <br />
        SP Pilots
        <br />
        Windguru
        <br />
        Paranaguá Pilots
      </FooterText>
    </ElWrapper>
    <ElWrapper>
      <FooterTitle>Mapa do Site</FooterTitle>
      <FooterText>
        <a href="#home">Home</a>
        <br />
        <a href="#sobrenos">Sobre Nós</a>
        <br />
        <a href="#servicos">Serviços</a>
        <br />
        <a href="#estrutura">Estrutura</a>
        <br />
        <a href="#galeria">Galeria</a>
        <br />
        <a href="#contato">Contato</a>
      </FooterText>
    </ElWrapper>
  </FooterWrapper>
);

export default Footer;

const FooterWrapper = styled.footer`
  background-color: #7ad6ff;
  color: #f8f6fa;
  padding: 70px 300px;
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 900px) {
    padding: 70px 24px;
  }
`;

const ElWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`;

const FooterText = styled.p`
  font-size: 12px;
  text-align: center;
`;

const FooterTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;
