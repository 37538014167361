import React from "react";
import styled from "styled-components";

import { ButtonAnchor } from "../Button";

import diver from "../../assets/diver.png";

const staticData = {
  en: {
    bigText1: "EXPERTISE IN ",
    bigTextBlue: "SUBAQUATIC SOLUTIONS",
    bigText2: " SINCE 1992",
    getInTouch: "Get in touch",
  },
  pt: {
    bigText1: "EXCELÊNCIA EM ",
    bigTextBlue: "SOLUÇÕES SUBAQUÁTICAS",
    bigText2: " DESDE 1992",
    getInTouch: "Entre em contato",
  },
};

const Hero = ({ data, language }) => {
  const staticInfo = staticData[language];
  return (
    <div id="home">
      <HeroWrapper>
        <div>
          <Bigtext>
            {staticInfo.bigText1}
            <span>{staticInfo.bigTextBlue}</span>
            {staticInfo.bigText2}.
          </Bigtext>
          <p>{data.descrioDeAbertura}</p>
          <ButtonAnchor href="#contato">{staticInfo.getInTouch}</ButtonAnchor>
        </div>
        <BigImgWrapper>
          <img src={diver} alt="" />
        </BigImgWrapper>
      </HeroWrapper>
      <CardWrapper>
        <Card>
          {data.icone1 && <Icon src={data.icone1.fields.file.url} alt="" />}
          <StyleText>{data.textoPrimeiroQuadrado || ""}</StyleText>
        </Card>
        <Card>
          {data.icone2 && <Icon src={data.icone2.fields.file.url} alt="" />}
          <StyleText>{data.textoSegundoQuadrado || ""}</StyleText>
        </Card>
        <Card>
          {data.icone3 && <Icon src={data.icone3.fields.file.url} alt="" />}
          <StyleText>{data.textoTerceiroQuadrado || ""}</StyleText>
        </Card>
      </CardWrapper>
    </div>
  );
};

export default Hero;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin: 5px auto;
`;

const StyleText = styled.p`
  font-size: 14px;
  color: #484b4d;
`;

const Card = styled.div`
  background: #fdfdfd;
  box-shadow: 0px 0px 12px 4px rgba(9, 43, 63, 0.15);
  border-radius: 8px;
  padding: 15px 30px;
  margin: 5px;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const HeroWrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 164px;
`;

const BigImgWrapper = styled.div`
  width: 50%;
  overflow: visible;
  position: relative;
  flex-basis: 50%;
  flex-shrink: 0;
  img {
    position: absolute;
    left: 0;
    top: -10%;
    width: 150%;
    max-width: 774px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const Bigtext = styled.h1`
  font-family: "Bebas Neue", sans-serif;
  font-size: 88px;
  line-height: 88px;
  margin: 0;
  span {
    color: #62c6fe;
  }
  @media (max-width: 900px) {
    font-size: 64px;
    line-height: 64px;
  }
`;
