
import styled, { css } from "styled-components";
import { Link } from "gatsby"

const buttonStyle = css`
  background-color: #62c6fe;
  color: #ffffff;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  display: inline-block;
  border: none;
`;

export const Button = styled.button`
  ${buttonStyle};
`;

export const ButtonAnchor = styled.a`
  ${buttonStyle};
`;

export const ButtonLink = styled(Link)`
  ${buttonStyle};
`;
