import React, { useRef } from "react";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import Title from "../Title";
import diver from "../../assets/diver.png";
import arrowLeft from "../../assets/chevron-left-r.svg";
import arrowRight from "../../assets/chevron-right-r.svg";

const staticData = {
  en: {
    aboutUs: "ABOUT US",
  },
  pt: {
    aboutUs: "SOBRE NÓS",
  },
};

const handleDragStart = (e) => e.preventDefault();

const SobreNos = ({ data, language }) => {
  const staticInfo = staticData[language];
  const itemsData = [
    {
      title: data.sobreNsTtulo1 || "",
      description: data.sobreNsTexto1 || "",
      photo: data.sobreNsImagem1 ? data.sobreNsImagem1.fields.file.url : "",
    },
    {
      title: data.sobreNsTtulo2,
      description: data.sobreNsTexto2 || "",
      photo: data.sobreNsImagem2 ? data.sobreNsImagem2.fields.file.url : "",
    },
    {
      title: data.sobreNsTtulo3 || "",
      description: data.sobreNsTexto3 || "",
      photo: data.sobreNsImagem3 ? data.sobreNsImagem3.fields.file.url : "",
    },
  ];

  const carouselRef = useRef(null);
  return (
    <div id="sobrenos">
      <Title>{staticInfo.aboutUs}</Title>
      <div>
        <AliceCarousel
          ref={carouselRef}
          mouseTracking
          items={items(itemsData).map((el) =>
            el(
              () => carouselRef.current.slidePrev(),
              () => carouselRef.current.slideNext()
            )
          )}
        />
      </div>
    </div>
  );
};

export default SobreNos;

const SlideWrapper = styled.div`
  display: flex;
`;
const SlideTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 40px;
`;
const SlidePhoto = styled.img`
  width: 430px;
  height: 300px;
  border-radius: 20px;
  margin-left: 130px;
  margin-right: 50px;
  @media (max-width: 800px) {
    display: none;
  }
`;
const SlideLinkWrapper = styled.div`
  display: flex;
`;
const SlideLink = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  img {
    width: 24px;
    margin-right: 8px;
  }
  flex: 1;
  background: transparent;
  border: 0;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 16px;
  &:last-child {
    justify-content: flex-end;
    img {
      margin-left: 8px;
      margin-right: 0;
    }
  }
`;

const StyleText = styled.p`
  font-size: 14px;
  color: #484b4d;
  text-align: justify;
`;

const items = (itemsData) =>
  itemsData.map(
    ({ title, description, photo }, i, arr) =>
      (prevSlide, nextSlide) =>
        (
          <div>
            <SlideWrapper>
              <div>
                <SlideTitle>{title}</SlideTitle>
                <StyleText>{description}</StyleText>
              </div>
              <SlidePhoto onDragStart={handleDragStart} src={photo} />
            </SlideWrapper>
            <SlideLinkWrapper>
              {arr[i - 1] ? (
                <SlideLink onClick={prevSlide}>
                  <img height="24" src={arrowLeft} />
                  {arr[i - 1].title}
                </SlideLink>
              ) : (
                <div />
              )}
              {arr[i + 1] ? (
                <SlideLink onClick={nextSlide}>
                  {arr[i + 1].title}
                  <img height="24" src={arrowRight} />
                </SlideLink>
              ) : (
                <div />
              )}
            </SlideLinkWrapper>
          </div>
        )
  );
