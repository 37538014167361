import React, { useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Title from "../Title";
import diver from "../../assets/diver.png";
import arrowLeft from "../../assets/chevron-left-r.svg";
import arrowRight from "../../assets/chevron-right-r.svg";
import wpp from "../../assets/wpp.png";
import fb from "../../assets/fb.png";

const staticData = {
  en: {
    theCompany: "The Company",
  },
  pt: {
    theCompany: "Estrutura",
  },
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const handleDragStart = (e) => e.preventDefault();

const Estrutura = ({ data, language }) => {
  const staticInfo = staticData[language];
  const infoData = [
    {
      title: data.estruturaTtulo1 || "",
      description: data.estruturaDescrio1 || "",
      photo: data.estruturaMdia1 || "",
    },
    {
      title: data.estruturaTtulo2 || "",
      description: data.estruturaDescrio2 || "",
      photo: data.estruturaMdia2 || "",
    },
    {
      title: data.estruturaTtulo3 || "",
      description: data.estruturaDescrio3 || "",
      photo: data.estruturaMdia3 || "",
    },
  ];

  return (
    <div id="estrutura">
      <Title>{staticInfo.theCompany}</Title>

      {infoData.map((el) => (
        <EstruturaWrapper>
          <div>
            <EstruturaTitle>{el.title}</EstruturaTitle>
            <StyleText>{el.description}</StyleText>
          </div>
          <SlideWrapper>
            <Slider {...settings}>
              {el.photo &&
                el.photo.map((photo) => <Item photo={photo.fields.file.url} />)}
            </Slider>
          </SlideWrapper>
        </EstruturaWrapper>
      ))}
    </div>
  );
};

export default Estrutura;

const EstruturaWrapper = styled.div`
  text-align: justify;
  display: flex;
  flex-direction: row;
  margin: 10px 0 30px;
  &:nth-child(odd) {
    & > h2 {
      text-align: right;
    }
    & > div:nth-child(2) {
      order: -1;
      margin-left: 0;
      margin-right: 60px;
    }
  }
`;

//const ElWrapper = styled.div`
// display: flex;
// flex-direction: column;
//`;

const EstruturaTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
`;

const SlideWrapper = styled.div`
  height: 310px;
  width: 442px;
  margin-left: 60px;
  @media (max-width:900px) {
    display: none;
  }
`;

const SlideLinkWrapper = styled.div`
  display: flex;
`;
const SlideLink = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  img {
    width: 24px;
    margin-right: 8px;
  }
  flex: 1;
  background: transparent;
  border: 0;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 16px;
  &:last-child {
    justify-content: flex-end;
    img {
      margin-left: 8px;
      margin-right: 0;
    }
  }
`;
const SlidePhoto = styled.img`
  /* width: 430px; */
  height: 300px;
  border-radius: 30px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Item = ({ photo }) => (
  <SlidePhoto
    onDragStart={handleDragStart}
    width="100%"
    height="310"
    src={photo}
  />
);

const StyleText = styled.p`
  font-size: 14px;
  color: #484b4d;
`;
