import React from "react";
import styled from "styled-components";

import Title from "../Title";
import diver from "../../assets/diver.png";

const staticData = {
  en: {
    gallery: "Gallery",
  },
  pt: {
    gallery: "Galeria",
  },
};

const Galeria = ({ data, language }) => {
  const staticInfo = staticData[language];
  return (
    <div id="galeria">
      <Title>{staticInfo.gallery}</Title>
      <ElWrapper>
        {data.galeria &&
          data.galeria.map((el) => <Square src={el.fields.file.url} alt="" />)}
      </ElWrapper>
    </div>
  );
};

export default Galeria;

const ElWrapper = styled.div`
  border-radius: 40px;
  overflow: hidden;
  font-size: 0;
`;

const MARGIN = 0.5;

const Square = styled.img`
  width: calc(25% - ${MARGIN * 2}px);
  height: 221px;
  margin: ${MARGIN}px;
  display: inline-block;
  object-fit: cover;
  @media (max-width: 900px) {
    width: calc(50% - ${MARGIN * 2}px);
  }
`;
