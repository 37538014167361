import styled from 'styled-components';

const Title = styled.h1`
   color: #7ad6ff;
   font-family: "Bebas Neue", sans-serif;
   font-size: 64px;
   margin-top: 90px;
   margin-bottom: 0;
`

export default Title
