import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

import mancha from "../assets/mancha.svg";

import "./variables.css";
import "./global.css";
import Seo from "./Seo";
import Footer from "./Footer";
import Topbar from "./Topbar";
import LocaleChange from "./LocaleChange";

const config = { attributes: true, childList: true, subtree: true };

const Template = ({ children, language }) => {
  const translateChatify = () => {
    if (document.querySelector('[placeholder="Ask your question here..."]')) {
      document.querySelector(
        '[placeholder="Ask your question here..."]'
      ).placeholder = "Pergunte aqui...";
      
      // if (document.querySelector('[class="pubble-cw-panel-dialog"]')) matchingElement.innerText = "Blablabla";
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(translateChatify);
    observer.observe(document.body, config);
    translateChatify();
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Argos Diving</title>
        <script
          type="text/javascript"
          src="/findAndReplaceDOMText.js"
          defer
        ></script>
        <script
          type="text/javascript"
          src="https://cdn.chatify.com/javascript/loader.js"
          defer
        ></script>
      </Helmet>
      <div
        className="pubble-app"
        data-app-id="105144"
        data-app-identifier="105144"
      />
      <GlobalStyle />
      <Topbar language={language} />
      <main>{children}</main>
      <LocaleChange i18n={language} />
      <Footer />
    </>
  );
};

export default Template;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f8f6fa;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    background-image: url(${mancha});
    background-size: 150vw;
    background-position: -15vw 620px;
    background-repeat: no-repeat;
  }
  * {
    box-sizing: border-box;
  }
`;
